<template>
  <div class="thing_category_add">
    <el-card>
      <el-row :gutter="20" class="left">
        <el-col :span="3">
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back">
            新增属性
          </i>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-form ref="Form" :rules="categoryRules" :model="info" label-width="120px" style="padding-left:40px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="属性名称" prop="attr_name">
              <el-input v-model="info.attr_name" maxlength="15" show-word-limit placeholder="请输入属性名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="12">
            <el-form-item label="属性类型" prop="attrtype_id">
              <el-select v-model="info.attrtype_id" placeholder="请选择属性类型">
                <el-option
                  v-for="(item,index) in attrtype_list"
                  :key="index"
                  :label="item.attrtype_name"
                  :value="item.attrtype_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="12">
            <el-form-item label="属性可选" prop="attr_type">
              <el-radio v-model="info.attr_type" label="0">唯一</el-radio>
              <el-radio v-model="info.attr_type" label="1">多选</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="12">
            <el-form-item label="录入方式" prop="attr_input_type">
              <el-radio v-model="info.attr_input_type" label="0">手工录入</el-radio>
              <el-radio v-model="info.attr_input_type" label="1">从列表中选择（一行代表一个可选值）</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="12">
            <el-form-item label="可选值列表" prop="attr_values">
              <el-input type="textarea" :rows="8" :disabled="info.attr_input_type!='1'" v-model="info.attr_values" placeholder="请输入可选值"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="12">
            <el-form-item label="排序" prop="sort_order">
              <el-input v-model.number="info.sort_order" placeholder="请输入排序"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" @click="subAdd">提交</el-button>
      <el-button type="default" @click="back">返回</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  components:{
    
  },
  data () {
    return {
      categoryRules:{
        attr_name:[
          {required: true, message:'请输入属性名称', trigger: 'blur'}
        ],
        attrtype_id:[
          {required: true, message:'请选择属性类型', trigger: 'blur'}
        ]
      },
      info:{
      	attr_id: '',
        attrtype_id:'',
        attr_name:'',
        attr_input_type:'1',
        attr_type:'1',
        attr_values: '',
        sort_order:99,
      },
      attrtype_list: [],
    }
  },
  created() {
    if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			this.getAttrtypeList();
      if (this.$route.query.attrtype_id) {
        this.info.attrtype_id = String(this.$route.query.attrtype_id);
      }
      console.log(this.info);
			if(this.$route.query.attr_id){
        this.info.attr_id = this.$route.query.attr_id;
				this.getAttrDetail();
			}
		}
  },
  methods: {
    getAttrtypeList(){
      var url = 'thing/attrtype_list_select';
      let params = {};
      this.fd_post(url, params).then((res) => {
        // console.log(res);
        if(res.status) {
          this.attrtype_list = res.list;
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
  	getAttrDetail(){
  		var url = 'thing/attr_detail';
			let params = {
				attr_id: this.info.attr_id
			};
			this.fd_post(url, params).then((res) => {
        console.log(res);
				if(res.status) {
					this.info = res.detail;
				} else {
					this.$message.error(res.msg);
				}
			}).catch((err) => {
				this.$message.error('网络错误');
			});
  	},
    back(){
      this.$router.go(-1)
    },
    //提交
    subAdd(){
      this.$refs.Form.validate(valid=>{
        console.log(this.info);
        if(!valid) return;
        else{
	        this.fd_post('thing/add_or_edit_attr', this.info).then((res) => {
        		if(res.status){
        			this.$refs.Form.resetFields();
        			this.$message.success("操作成功");
        			this.$router.push({path: '/thing_attr', query: {attrtype_id: this.info.attrtype_id}});
        		}else{
        			this.$message.error(res.msg);
        		}
	        }).catch((err) => {
	            this.$message.error('网络错误');
	        });
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
  .thing_category_add{
    .left{
      text-align: left;
    }
    #allmap{
      height: 450px;
      width: 100%;
      margin: 10px 0;
    }
    .previewUpload{
      display: flex;
      flex-wrap: wrap;
      .previewItems{
        width: 178px;
        height: 178px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        margin:0 10px 10px 0;
        cursor: pointer;
        position: relative;
        .previewadd {
          font-size: 28px;
          color: #8c939d;
          width: 178px;
          height: 178px;
          line-height: 178px;
          text-align: center;
        }
        &:hover{
          border-color: #409EFF;
        }
      }
      .previewItem{
        width: 178px;
        height: 178px;
        border-radius: 6px;
        margin:0 10px 10px 0;
        cursor: pointer;
        position: relative;
        .delIcon{
          display: none;
        }
        &:hover{
          .delIcon{
            display: block;
            position: absolute;
            top: 5px;
            right: 5px;
            color: white;
            font-size: 30px;
          }
        }
        
        img{
          width: 100%;
          height: 100%;
        }
        
      }
    }

  }
</style>